import React from "react";
import { SectionWrapper } from "../../components/Common";
import { styled } from "styled-components";
import {
  Evanto,
  Figma,
  Google,
  PartnersBG,
  PlusSign,
  Sketch,
  Slack,
  VideoHive,
  colors,
} from "../../assets";

const partnerIconData = [
  {
    icon: <Slack />,
  },
  {
    icon: <Google />,
  },
  {
    icon: <Evanto />,
  },
  {
    icon: <Sketch />,
  },
  {
    icon: <Figma />,
  },
  {
    icon: <VideoHive />,
  },
];
const PartnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
    gap: 2rem;
  }
  @media screen and (max-width: 600px) {
    padding-bottom: 0;
  }
`;

const PartnerRight = styled.div`
  width: 50%;
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;
const PartnerHeader = styled.h1`
  font-family: Poppins;
  font-size: 150px;
  font-weight: 700;
  line-height: 150px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.darkBlueColor};
`;
const PartnerPlus = styled.div``;

const PartnerPlusWrapper = styled.div`
  display: flex;
`;
const PartnerSpan = styled.span`
  font-family: Rubik;
  font-size: 22px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: ${colors.headColorBlue};
`;
const PartnerBGimg = styled.div`
  background: url(${PartnersBG});
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vh;

  @media screen and (max-width: 768px) {
    height: 50vh;
  }

  @media screen and (max-width: 500px) {
    height: 35vh;
  }
`;
const PartnerLeft = styled.div`
  width: 50%;
  @media screen and (max-width: 1100px) {
    width: 100%;
  }
`;
const PartnerLeftContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 0rem 5rem;
  div:nth-child(-n + 3) {
    border-bottom: 1px solid ${colors.horizontalColor};
  }
  div:nth-child(-n + 2) {
    border-right: 1px solid ${colors.horizontalColor};
  }
  div:nth-child(-2n + 4) {
    border-right: 1px solid ${colors.horizontalColor};
  }
  div:nth-child(-3n + 5) {
    border-right: 1px solid ${colors.horizontalColor};
  }

  @media screen and (max-width: 800px) {
    padding: 0;
  }

  @media screen and (max-width: 600px) {
    display: grid;
    grid-template-columns: auto auto;
    padding: 0;
    justify-content: center;

    div:nth-child(-n + 3) {
      border-bottom: 0px solid ${colors.horizontalColor};
    }
    div:nth-child(-n + 2) {
      border-right: 0px solid ${colors.horizontalColor};
    }
    div:nth-child(-2n + 4) {
      border-right: 0px solid ${colors.horizontalColor};
    }
    div:nth-child(-3n + 5) {
      border-right: 0px solid ${colors.horizontalColor};
    }
  }
`;
const PartnerLeftSlack = styled.div`
  // width: 130px;
  // height: 100px;
  padding: 1rem;

  svg {
    width: 130px;
    height: 130px;
  }

  @media screen and (max-width: 2200px) {
    padding: 1.5rem;
  }

  @media screen and (max-width: 1500px) {
    padding: 1rem;
  }
  @media screen and (max-width: 1100px) {
    padding: 3rem;
  }
  @media screen and (max-width: 800px) {
    padding: 1rem;
  }
  @media screen and (max-width: 500px) {
    padding: 1rem;

    svg {
      width: 100px;
      height: 100px;
    }
  }
`;

const PartnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: inherit;
`;

export const PartnersWorldWide = () => {
  return (
    <SectionWrapper>
      <PartnerWrapper>
        <PartnerRight>
          <PartnerBGimg>
            <PartnerContainer>
              <PartnerPlusWrapper>
                <PartnerHeader>80</PartnerHeader>
                <PartnerPlus>
                  <PlusSign />
                </PartnerPlus>
              </PartnerPlusWrapper>
              <PartnerSpan>Partners in world wide</PartnerSpan>
            </PartnerContainer>
          </PartnerBGimg>
        </PartnerRight>
        <PartnerLeft>
          <PartnerLeftContainer>
            {partnerIconData.map((partner, index) => {
              return (
                <>
                  {" "}
                  <PartnerLeftSlack key={index}>
                    {partner.icon}
                  </PartnerLeftSlack>
                </>
              );
            })}
          </PartnerLeftContainer>
        </PartnerLeft>
      </PartnerWrapper>
    </SectionWrapper>
  );
};
