import { styled } from "styled-components";
import { SectionWrapper } from "../Common";
import { MailIcon, colors } from "../../assets";
import { socials } from "../../assets/constants/content";

const SectionContainer = styled.div`
  display: 100%;
  /* border-bottom: 1px solid ${colors.darkBlueColor}; */
  -webkit-box-shadow: 0px 0px 5px 0px ${colors.darkBlueColor};
  -moz-box-shadow: 0px 0px 5px 0px ${colors.darkBlueColor};
  box-shadow: 0px 0px 5px 0px ${colors.darkBlueColor};
`;

const SubNavContainer = styled.div`
  width: 100%;
  padding: 0.8rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Rubik", sans-serif;
  color: ${colors.darkBlueColor};
`;
const WelcomeText = styled.p`
  font-size: 14px;
  font-weight: 400;

  span {
    font-weight: 700;
    font-size: 16px;
  }
`;

const ContactDetailsWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const Detail = styled.p`
  font-weight: 700;
  span {
    font-weight: 400;
  }
`;

const SocialConnectionsWrapper = styled.div`
  display: flex;
  gap: 1.1rem;
`;

const RedirectionAnchor = styled.a``;
export const SubNav = () => {
  return (
    <SectionContainer>
      <SectionWrapper>
        <SubNavContainer>
          <WelcomeText>
            {/* Welcome to our <span>Cleaning Service!</span> */}
            اهلًا بكم في شركة مهند للاستقدام
          </WelcomeText>
          <ContactDetailsWrapper>
            <Detail>للاتصال: 966563363544</Detail>
            <hr />
            <Detail>
              <MailIcon /> &nbsp; <span>muhannad.recruitment@gmail.com</span>:
              ايميل
            </Detail>
            <hr />
            <SocialConnectionsWrapper>
              {socials?.map((social) => {
                return (
                  <RedirectionAnchor href={social?.url} target="_blank">
                    {social.icon}
                  </RedirectionAnchor>
                );
              })}
            </SocialConnectionsWrapper>
          </ContactDetailsWrapper>
        </SubNavContainer>
      </SectionWrapper>
    </SectionContainer>
  );
};
