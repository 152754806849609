import React from "react";
import { styled } from "styled-components";
import { SectionSpacer, SectionWrapper } from "../../components/Common";
import { StatisticsBg, colors } from "../../assets";
import CountUp from "react-countup";

const StatisticsData = [
  {
    count: 100,
    name: "SATISFIED CLIENTS",
    id: 0,
  },
  {
    count: 10,
    name: "TIMES GOOD",
    id: 0,
  },
  {
    count: 87,
    name: "BEST RATING",
    id: 1,
  },
  {
    count: 70,
    name: "CENTRES",
    id: 1,
  },
];

const StatisticsContainer = styled.div`
  font-family: "Rubik", sans-serif;
  width: 100%;
  background-image: url(${StatisticsBg});
  background-position: left top;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 800px) {
    height: auto;
  }
`;
const StatisticBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${colors.contactbannerLightBLueColor};
`;

const OverlayContent = styled.div`
  position: relative;
  z-index: 2;
  color: #fff;
  padding: 3rem 0;
  width: 80%;
  margin: auto;

  @media screen and (min-width: 1921px) {
    width: 70%;
    margin: auto;
  }
  @media screen and (max-width: 800px) {
    width: 90%;
  }
`;
const StatisticsWrapperContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 800px) {
    /* display: flex;
    flex-wrap: wrap;
    gap: 4rem; */
  }
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 2rem;
  }
`;
const CountupContainer = styled.div``;
const CountupWrapper = styled.div`
  font-size: 70px;
  font-weight: 600;
  color: ${colors.darkBlueColor};
`;
const PlusContainer = styled.div`
  font-size: 62px;
  @media screen and (max-width: 1100px) {
    font-size: 50px;
  }
  @media screen and (max-width: 800px) {
    font-size: 38px;
  }
`;
const PlusWrapper = styled.span``;
const NameWrapper = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.primaryBlueColor};

  @media screen and (max-width: 1100px) {
    font-size: 14px;
  }
  @media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 42px;
  }
`;

const Title = styled.h2`
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
  margin-bottom: 5rem;
  color: ${colors.darkBlueColor};

  @media screen and (max-width: 800px) {
    font-size: 30px;
    line-height: 42px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 2rem;
  }
`;

export const Statistics = () => {
  return (
    <SectionSpacer>
      <StatisticsContainer>
        <StatisticBackground></StatisticBackground>
        <OverlayContent>
          {/* <SectionWrapper> */}
          <Title>Customer Success Metrics</Title>
          <StatisticsWrapperContainer>
            {StatisticsData.map((item, index) => {
              return (
                <CountupContainer key={index}>
                  <CountupWrapper>
                    <CountUp
                      start={0}
                      end={item.count}
                      enableScrollSpy={true}
                      redraw={true}
                      className=""
                    >
                      {({ countUpRef, start }) => (
                        <PlusContainer>
                          <PlusWrapper ref={countUpRef} />
                          <PlusWrapper>
                            {item.id === 0 ? "k+" : "+"}
                          </PlusWrapper>
                        </PlusContainer>
                      )}
                    </CountUp>
                  </CountupWrapper>
                  <NameWrapper>{item.name}</NameWrapper>
                </CountupContainer>
              );
            })}
          </StatisticsWrapperContainer>
          {/* </SectionWrapper> */}
        </OverlayContent>
      </StatisticsContainer>
    </SectionSpacer>
  );
};
