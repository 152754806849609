import React from "react";
import { styled } from "styled-components";
import { colors } from "../../assets";
import { TimelineSteps } from "../../components";

const RecruitmentProcessContainer = styled.div`
  width: 80%;
  margin: auto;
  padding: 5rem 0;

  @media screen and (min-width: 1921px) {
    width: 70%;
    margin: auto;
  }
`;

const RecruitmentProcessHeading = styled.h2`
  font-size: 3rem;
  font-weight: 500;
  color: ${colors.primaryBlueColor};
  margin-bottom: 2rem;
`;

const HrLine = styled.hr`
  border: 0;
  margin: auto;
  width: 80%;
  height: 1px;
  background-image: linear-gradient(to right, #f7fafc, #c8d3d9, #f7fafc);
`;

const TimelineContainer = styled.div`
  @media screen and (max-width: 800px) {
    margin-top: 3rem;
  }
`;

export const RecruitmentProcess = () => {
  return (
    <RecruitmentProcessContainer>
      <RecruitmentProcessHeading>عمليات التوظيف</RecruitmentProcessHeading>
      <HrLine></HrLine>
      <TimelineContainer>
        <TimelineSteps />
      </TimelineContainer>
    </RecruitmentProcessContainer>
  );
};
