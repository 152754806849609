import styled, { css } from "styled-components";
import { AppStore, CountriesBG } from "../../assets";

const FlipCardWrapper = styled.div`
  background-color: transparent;
  width: 20%;
  height: 400px;
  perspective: 1000px;
  /* margin-top: 1rem; */

  &:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  @media screen and (max-width: 1500px) {
    width: 23%;
  }
  @media screen and (max-width: 1100px) {
    width: 30%;
  }
  @media screen and (max-width: 800px) {
    width: 40%;
  }
  @media screen and (max-width: 600px) {
    width: 75%;
    margin: auto;
    margin-top: 2rem;
    height: 340px;
  }
`;

const FlipCardInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
`;

const FlipCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
`;

const FlipCardFront = styled(FlipCard)`
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: url(${CountriesBG});
  background-position: left top;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 10px;

  /* &:hover {
    transform: rotateY(180deg);
  } */
`;

const FlipCardBack = styled(FlipCard)`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #eef4f8;
  transform: rotateY(180deg);
  border: 1px solid #c8d3d9;
  border-radius: 10px;
`;

const StyledH1 = styled.h1`
  font-size: 24px;
  font-weight: 400;
  line-height: 175%;
  /* margin-bottom: 10px; */
  @media screen and (max-width: 600px) {
    font-size: 20px;
  }
`;

const StyledP = styled.p`
  font-size: 16px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

const FlagContainer = styled.div`
  ${(props) =>
    props.width &&
    props.height &&
    css`
      svg {
        width: ${props.width};
        height: ${props.height};

        object-fit: cover;
        margin-bottom: 30px;
        border: 5px solid #dddddd9d;
        border-radius: 10px;
      }
      @media (max-width: 600px) {
        svg {
          width: 95px;
          height: 60px;
        }
      }
    `}
`;
const CountryName = styled.div`
  font-size: 32px;
  color: white;
`;
const FrontSubHeading = styled.div`
  color: #71869e;
`;

export const FlipCardComponent = ({ data }) => {
  return (
    <FlipCardWrapper>
      <FlipCardInner className="flip-card-inner">
        <FlipCardFront>
          <FlagContainer width="143px" height="auto">
            {data.flag}
          </FlagContainer>
          <CountryName>{data.country}</CountryName>
          <FrontSubHeading>أفضل العمالة المنزلية المدربة</FrontSubHeading>
        </FlipCardFront>
        <FlipCardBack>
          <StyledH1>{data.backHeading}</StyledH1>
          <StyledP>{data.backSubHeading}</StyledP>
          <StyledH1>عاملة منزلية</StyledH1>
          <StyledP>{data.period}: مدة الاستقدام</StyledP>
          <StyledP> {data.salary}: الراتب </StyledP>
          <StyledP>{data.contractCost} :تكلفة التعاقد شاملة الضريبة </StyledP>
        </FlipCardBack>
      </FlipCardInner>
    </FlipCardWrapper>
  );
};
