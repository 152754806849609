import { styled } from "styled-components";
import { Map } from "../../components";

const MapSectionHeader = styled.h2`
  font-family: Poppins;
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
  width: 65%;
  margin: auto;
  max-width: 99vw;
  padding: 3rem 0;

  @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 32px;
    line-height: 150%;
  }
  @media screen and (max-width: 500px) {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
`;

export const MapSection = () => {
  return (
    <>
      <MapSectionHeader>مقر شركة مهند للاستقدام</MapSectionHeader>
      <Map />
    </>
  );
};
