import React from "react";
import { SectionSpacer, SectionWrapper } from "../../components/Common";
import { styled } from "styled-components";
import { DashedBackground, colors } from "../../assets";
import { HowWeWorkData } from "../../assets/constants/content";

const MainHeading = styled.div`
  font-family: Rubik;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.primaryBlueColor};
  @media screen and (max-width: 500px) {
    font-size: 25px;
    text-align: start;
  }
`;

const SubHeading = styled.div`
  font-family: Poppins;
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;
  color: ${colors.darkBlueColor};
  margin: auto;

  @media screen and (max-width: 1100px) {
    font-size: 32px;
    line-height: 130%;
  }
  @media screen and (max-width: 800px) {
    font-size: 32px;
  }
  @media screen and (max-width: 500px) {
    font-size: 32px;
    line-height: 42px;
    text-align: start;
  }
`;

const Paragraph = styled.p`
  font-family: Rubik;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #5a6079;
  width: 50%;
  margin: auto auto 2rem auto;

  @media screen and (max-width: 800px) {
    width: 70%;
    font-size: 14px;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    text-align: start;
  }
`;
const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  @media screen and (max-width: 500px) {
    justify-content: flex-start;
  }
`;

const HeadingBorderBottom = styled.div`
  border-bottom: 2px solid ${colors.primaryBlueColor};
  width: 4%;

  @media screen and (max-width: 500px) {
    width: 10%;
  }
`;

const WorkStepsWrapper = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;

  @media screen and (max-width: 500px) {
    flex-direction: column;
    gap: 2rem;
  }
`;

const WorkStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  font-family: "Rubik", sans-serif;
`;

const WorkStepsIcon = styled.div`
  width: 90px;
  height: 90px;
  margin: auto;
  border-radius: 45px;
  box-shadow: 0px 13px 35px 0px #2e69ff33;
  color: ${colors.whiteColor};
  background: ${colors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const WorkStepsHeading = styled.h3`
  color: ${colors.darkBlueColor};

  @media screen and (max-width: 800px) {
    font-size: 1rem;
  }
`;
const WorkStepsData = styled.p`
  width: 72%;
  margin: auto;
  color: ${colors.grayTextColor};

  @media screen and (max-width: 800px) {
    width: 90%;
    font-size: 0.9rem;
    line-height: 150%;
  }
`;
const WorkStepsBorderBottom = styled.div`
  border-bottom: 4px solid ${colors.primaryBlueColor};
  width: 9%;
  margin: auto;
`;
const NumberWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 85%;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  box-shadow: 0px 13px 35px 0px #2e69ff33;
  background-color: ${colors.primaryBlueColor};
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) and (max-width: 1023px) {
    top: 75%;
    left: 70%;
  }
`;

const Container = styled.div`
  background-image: url(${DashedBackground});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;

  @media screen and (max-width: 900px) {
    background-image: none;
  }
`;

export const HowWeWork = () => {
  return (
    <>
      <SectionWrapper>
        <SectionSpacer>
          <Container>
            <HeadingWrapper>
              {/* <HeadingBorderBottom />
              <MainHeading>How we work</MainHeading>
              <HeadingBorderBottom /> */}
            </HeadingWrapper>
            <SubHeading>الية طلب خدماتنا</SubHeading>
            {/* <Paragraph>
              {" "}
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s book.
            </Paragraph> */}
            <WorkStepsWrapper>
              {HowWeWorkData.map((workdata, i) => {
                return (
                  <WorkStepsContainer key={i}>
                    <WorkStepsIcon>
                      {workdata.icon}
                      <NumberWrapper>{workdata.number}</NumberWrapper>
                    </WorkStepsIcon>
                    <WorkStepsHeading>{workdata.heading}</WorkStepsHeading>
                    <WorkStepsBorderBottom />
                    {/* <WorkStepsData>{workdata.data}</WorkStepsData> */}
                  </WorkStepsContainer>
                );
              })}
            </WorkStepsWrapper>
          </Container>
        </SectionSpacer>
      </SectionWrapper>
    </>
  );
};
