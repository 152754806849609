// import { useEffect, useRef, useState } from "react";
import {
  AboutCleaning,
  Banner,
  ClientsTestimonials,
  DownloadApp,
  FAQ,
  Footer,
  MapSection,
  RecruitmentReferences,
  // Navbar,
  // NewsBlog,
  OurServices,
  // PartnersWorldWide,
} from "../../containers";
// import { HowWeWork } from "../../containers/HowWeWork";
import { WorkWithTeam } from "../../containers/WorkWithTeam";

export const Home = () => {
  return (
    <>
      {/* <Navbar /> */}
      <Banner />
      {/* <HowWeWork /> */}
      <AboutCleaning />
      <OurServices />
      <WorkWithTeam />
      {/* <DownloadApp /> */}
      <FAQ />
      {/* <ClientsTestimonials /> */}
      {/* <PartnersWorldWide /> */}
      {/* <NewsBlog /> */}
      <RecruitmentReferences />
      <MapSection />
      <Footer />
    </>
  );
};
