import { styled } from "styled-components";
import { colors } from "../../assets";
import { SectionSpacer, SectionWrapper } from "../../components/Common";
import Carousel from "react-multi-carousel";
import { recruitmentReferenceData } from "../../assets/constants/content";
import { Link } from "../../assets";

const RecruitmentReferencesSection = styled.section`
  width: 100%;
  background: ${colors.grayBgColor};
`;

const SectionTitle = styled.section`
  font-family: Poppins;
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
  width: 65%;
  margin: auto;

  @media screen and (max-width: 800px) {
    font-size: 32px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
const ReferencesCarouselContainer = styled.div`
  display: flex;
  gap: 2rem;
  /* max-width: 100%; */
  margin: 4rem 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const ReferencesCarousel = styled(Carousel)`
  display: flex;
  width: 100%;
`;
const ReferenceLink = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: translateY(-0.5rem);
  }
`;

const ReferenceImage = styled.img`
  /* cursor: url(https://www.svgrepo.com/show/525988/link.svg), auto; */
  width: 150px;
  object-fit: contain;
  aspect-ratio: 1/1;
`;

export const RecruitmentReferences = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <RecruitmentReferencesSection>
        <SectionWrapper>
          <SectionSpacer>
            <SectionTitle>شركاؤنا</SectionTitle>
            <ReferencesCarouselContainer>
              <ReferencesCarousel
                responsive={responsive}
                infinite
                removeArrowOnDeviceType={[
                  "tablet",
                  "mobile",
                  "desktop",
                  "superLargeDesktop",
                ]}
              >
                {recruitmentReferenceData.map((reference, index) => {
                  return (
                    <ReferenceLink
                      href={reference.url}
                      target="_blank"
                      key={index}
                    >
                      <ReferenceImage
                        src={reference.logo}
                        alt={reference.name}
                      />
                    </ReferenceLink>
                  );
                })}
              </ReferencesCarousel>
            </ReferencesCarouselContainer>
          </SectionSpacer>
        </SectionWrapper>
      </RecruitmentReferencesSection>
    </>
  );
};
