export const colors = {
  primaryBlueColor: "#2F80ED",
  darkBlueColor: "#000E39DE",
  whiteColor: "#FFFFFF",
  blackColor: "#000000",
  blackColorOpacity75: "#000000bf",
  grayTextColor: "#5A6079",
  headColorBlue: "#3A4268",
  grayBgColor: "#F3F4F8",
  footerBlackColor: "#151515",
  footerBorderBottomColor: " #FFD532",
  footerTextColor: "#c2d5e7",
  footerInputColor: "#333333",
  horizontalColor: "#D1D4DC",
  contactbannerLightBLueColor: "#2f80ed13",
};
