import React from "react";
import { SectionSpacer, SectionWrapper } from "../../components/Common";
import { styled } from "styled-components";
import { AppStore, PlayStore } from "../../assets";

const DownloadStoreContainer = styled.div``;
const DownloadStoreHeader = styled.h2`
  font-family: Poppins;
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
  width: 65%;
  margin: auto;

  @media screen and (max-width: 900px) {
    width: 100%;
    font-size: 32px;
    line-height: 150%;
  }
  @media screen and (max-width: 500px) {
    font-size: 28px;
    width: 100%;
    text-align: center;
  }
`;
const DownloadStoreAppDiv = styled.div`
  /* width: 20%;
  height: 20%; */
`;

const DownloadStoreApp = styled.div`
  @media screen and (max-width: 800px) {
    svg {
      width: 100%;
      height: 100%;
    }
  }
  /* @media screen and (max-width: 500px) {
    svg {
      width: 320px;
      height: 100px;
    }
  } */
`;

const DownloadStorePlayDiv = styled.div``;

const DownloadStorePlay = styled.div`
  @media screen and (max-width: 800px) {
    svg {
      width: 100%;
      height: 100%;
    }
  }
  /* @media screen and (max-width: 500px) {
    svg {
      width: 320px;
      height: 100px;
      margin: auto;
    }
  } */
`;
const DownloadStoreImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  padding: 2rem;

  @media screen and (max-width: 500px) {
    gap: 1rem;
  }
`;

const Breakpoints = styled.br`
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const DownloadApp = () => {
  return (
    <SectionWrapper>
      <SectionSpacer>
        <DownloadStoreContainer>
          <DownloadStoreHeader>
            Download App Book Your <Breakpoints />
            Appointment
          </DownloadStoreHeader>
          <DownloadStoreImgWrapper>
            {" "}
            <DownloadStoreAppDiv>
              <DownloadStoreApp>
                <AppStore />
              </DownloadStoreApp>
            </DownloadStoreAppDiv>
            <DownloadStorePlayDiv>
              <DownloadStorePlay>
                <PlayStore />
              </DownloadStorePlay>
            </DownloadStorePlayDiv>
          </DownloadStoreImgWrapper>
        </DownloadStoreContainer>
      </SectionSpacer>
    </SectionWrapper>
  );
};
