// import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
// import L from "leaflet";
import { styled } from "styled-components";

// import "leaflet/dist/leaflet.css";
// import icon from "leaflet/dist/images/marker-icon.png";
// import iconShadow from "leaflet/dist/images/marker-shadow.png";

const ContactMap = styled.div`
  width: 99.8%;
  margin: auto;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const Contact = styled.iframe`
  width: 100%;
  height: 575px;
  border: 1px solid #ccc;

  @media screen and (max-width: 1500px) {
    height: 560px;
  }
  @media screen and (max-width: 1100px) {
    height: 485px;
  }
`;

export const Map = () => {
  // const mapCenter = [20.5937, 78.9629];
  // const locations = [
  //   {
  //     position: [24.592208702367056, 46.59611587116448],
  //     name: "Riyadh, Saudi Arabia",
  //   },
  //   // { position: [23.685, 90.3563], name: "Bangladesh" },
  //   // { position: [20.5937, 78.9629], name: "India" },
  //   // { position: [22.5937, 74.9629], name: "India" },
  //   // { position: [9.145, 40.4897], name: "Ethiopia" },
  //   // { position: [1.3733, 32.2903], name: "Uganda" },
  //   // { position: [-1.2921, 36.8219], name: "Kenya" },
  //   // { position: [12.8797, 121.774], name: "Philippines" },
  // ];

  // let DefaultIcon = L.icon({
  //   iconUrl: icon,
  //   shadowUrl: iconShadow,
  // });

  // L.Marker.prototype.options.icon = DefaultIcon;
  return (
    <>
      <ContactMap>
        <Contact
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3627.9190662612696!2d46.596126600000005!3d24.591989199999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1122d8e27c6d%3A0xc862614e6904c3a9!2z2LTYsdmD2Kkg2YXZh9mG2K8g2YTZhNin2LPYqtmC2K_Yp9mFIE11aGFubmFkIFJlY3J1aXRtZW50IENvbXBhbnk!5e0!3m2!1sen!2sin!4v1711488087800!5m2!1sen!2sin"
          title="Example iframe"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></Contact>
      </ContactMap>
      {/* <MapContainer
        center={mapCenter}
        zoom={4}
        style={{ height: "70vh", width: "100%" }}
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.cleaning.com/copyright">Cleaning</a> contributors'
        />
        {locations.map((location, index) => (
          <Marker key={index} position={location.position}>
            <Popup>{location.name}</Popup>
          </Marker>
        ))}
      </MapContainer> */}
    </>
  );
};

{
  /* <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3627.9190662612696!2d46.596126600000005!3d24.591989199999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1122d8e27c6d%3A0xc862614e6904c3a9!2z2LTYsdmD2Kkg2YXZh9mG2K8g2YTZhNin2LPYqtmC2K_Yp9mFIE11aGFubmFkIFJlY3J1aXRtZW50IENvbXBhbnk!5e0!3m2!1sen!2sin!4v1711488087800!5m2!1sen!2sin"
  width="600"
  height="450"
  style="border:0;"
  allowfullscreen=""
  loading="lazy"
  referrerpolicy="no-referrer-when-downgrade"
></iframe>; */
}
