import { styled } from "styled-components";
import { BannerBG, VideoIcon, colors } from "../../assets";
import Typist from "react-typist";
import { useState, useEffect } from "react";
import { SectionWrapper } from "../../components/Common";

const BannerContainer = styled.section`
  height: 85vh;
  background: url(${BannerBG});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  color: ${colors.blackColor};
  text-align: left;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 800px) {
    background-position: 85%;
    background-size: cover;
    height: 70vh;
  }

  @media screen and (max-width: 500px) {
    background-position: center;
    background-size: cover;
  }
`;
const BannerParagraph = styled.p`
  font-family: Rubik, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  width: 40%;
  line-height: 150%;
  padding: 1rem 0rem;

  @media screen and (max-width: 1200px) {
    width: 70%;
  }
  @media screen and (max-width: 800px) {
    width: 70%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
const TypistComponent = styled(Typist)`
  width: 50%;
  line-height: 120%;
  font-weight: 700;
  color: ${colors.blackColor};
  font-size: 3.8rem;
  text-align: left;

  @media screen and (max-width: 1200px) {
    width: 70%;
  }
  @media screen and (max-width: 800px) {
    width: 80%;
    font-size: 3rem;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    font-size: 2.3rem;
  }
`;

const ServiceButton = styled.button`
  background-color: ${colors.whiteColor};
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 1rem 1.6rem;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  color: ${colors.blackColor};
  margin-left: 1rem;
  white-space: nowrap;
`;
const VideoButton = styled.button`
  background-color: ${colors.primaryBlueColor};
  outline: none;
  border: none;
  width: 57px;
  float: left;
  height: 57px;
  border-radius: 57px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 25%;
  @media screen and (max-width: 1200px) {
    width: 35%;
  }
  @media screen and (max-width: 800px) {
    width: 45%;
  }
  @media screen and (max-width: 600px) {
    width: 80%;
  }
`;
export const Banner = () => {
  const [count, setCount] = useState(1);

  useEffect(() => {
    setCount(1);
  }, [count]);

  return (
    <>
      <BannerContainer>
        <SectionWrapper>
          <div>
            <div>
              <span> شركة مهند للاستقدام</span>
              {count ? (
                <TypistComponent
                  avgTypingDelay={50}
                  onTypingDone={() => setCount(0)}
                >
                  <span>
                    {" "}
                    أفضل <br></br>خدمات التوظيف
                  </span>
                  <TypistComponent.Backspace count={8} delay={800} />
                  <span> خدمات عقود العمل الشهري! </span>
                </TypistComponent>
              ) : (
                ""
              )}
              <BannerParagraph>
                في شركة مهند للاستقدام غايتنا تقديم افضل واسرع خدمة لعملائنا،
                الان استفد من خدماتنا بكل يسر وسهولة.
              </BannerParagraph>
            </div>
            {/* <ButtonsWrapper>
              <VideoButton>
                <VideoIcon />
              </VideoButton>
              <ServiceButton>OUR SERVICES</ServiceButton>
            </ButtonsWrapper> */}
          </div>
        </SectionWrapper>
      </BannerContainer>
    </>
  );
};
