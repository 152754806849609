import { styled } from "styled-components";
import { Admin, BlueLeft, BlueRight, File, colors } from "../../assets";
import { SectionWrapper } from "../../components/Common";
import Slider from "react-slick";
import { blogData } from "../../assets/constants/content";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Container = styled.div`
  padding: 4rem 0;
  background: #f3f4f8;
  @media screen and (max-width: 600px) {
    margin: 3rem 0rem 4rem 0rem;
  }
`;

const HeadingContainer = styled.div`
  margin: 10px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Heading = styled.p`
  color: ${colors.headColorBlue};
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 29.25px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Line = styled.p`
  background: ${colors.primaryBlueColor};
  width: 30px;
  height: 2px;
  flex-shrink: 0;
  margin-left: 1rem;
`;

const SubHeading = styled.h6`
  color: ${colors.darkBlueColor};
  font-size: 36px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 46.8px;
  text-align: start;
  width: 95%;

  @media screen and (max-width: 800px) {
    width: 80%;
    font-size: 28px;
    line-height: 150%;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Cards = styled.div`
  border-radius: 4px;
  background: #fff;
  margin: 10px; /* Add margin here */
  position: relative;
`;

const CardContainer = styled.div`
  width: 100%;
`;
const BlogImage = styled.img`
  width: 100%;
  height: 230px;
  flex-shrink: 0;

  @media screen and (max-width: 800px) {
    height: 180px;
    object-fit: cover;
    object-position: top;
  }
`;
const ArrowImage = styled.img`
  width: 18px;
  height: 24px;
  flex-shrink: 0;
`;

const HeadingTitle = styled.div`
  width: 45%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const HeadingDescription = styled.div`
  width: 45%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Description = styled.p`
  color: #3a4268;
  font-size: 16px;
  font-family: Rubik;
  line-height: 26px;
  text-align: start;
  width: 80%;
`;

const AboutBlogContainer = styled.div`
  padding: 3rem 1rem;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid #eaebf0;
  background: #fff;
  display: flex;
  gap: 0.7rem;
  flex-direction: column;

  @media screen and (max-width: 800px) {
    padding: 2rem 1rem;
    padding-bottom: 1rem;
  }
`;

const IconContainer = styled.div`
  display: flex;
`;

const IconTest = styled.div`
  display: flex;
  align-items: center;
  width: 35%;
`;
const Text = styled.p`
  color: #676f95;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  line-height: 22.75px;
  text-transform: uppercase;
  margin-left: 8px;
`;

const CardHeading = styled.h5`
  color: #000e39;
  font-size: 24px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 32px;
  text-align: start;

  @media screen and (max-width: 800px) {
    font-size: 1rem;
    line-height: 150%;
  }
`;

const LineConatiner = styled.div`
  border-bottom: 1px solid #eaeaea;
`;

const Linetwo = styled.p`
  background: ${colors.primaryBlueColor};
  width: 37px;
  height: 3px;
  flex-shrink: 0;
`;

const BlogDiscription = styled.p`
  color: #3a4268;
  font-size: 15px;
  font-family: Rubik;
  line-height: 26px;
  text-align: start;

  @media screen and (max-width: 800px) {
    font-size: 0.8rem;
    line-height: 150%;
  }
`;
const ReadMoreButton = styled.p`
  text-align: start;
`;
const ButtonText = styled.span`
  color: #000e39;
  font-size: 14px;
  font-family: Rubik;
  font-weight: 700;
  line-height: 22.75px;
  text-transform: uppercase;
  cursor: pointer;
  border-bottom: 1px solid gray;
  padding-bottom: 0.2rem;
`;

const Plues = styled.span`
  color: #000e39;
  font-size: 18px;
  font-family: Rubik;
  font-weight: 700;
  line-height: 29.25px;
  text-transform: uppercase;
`;

const DateCard = styled.div`
  padding: 14.5px 14.33px 14.5px 14.67px;
  background-color: ${colors.primaryBlueColor};
  position: absolute;
  left: 1rem;
  top: -50px;
`;
const Date = styled.p`
  color: #fff;
  text-align: center;
  font-size: 30px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 30px;
`;
const MonthYear = styled.p`
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: Rubik;
  line-height: 16px;
`;

const DateConatiner = styled.div`
  position: relative;
`;

export const NewsBlog = () => {
  const CustomPrevArrow = (props) => (
    <div className="blog-arrow-left" onClick={props.onClick}>
      <ArrowImage src={BlueLeft} alt={BlueLeft} />
    </div>
  );

  const CustomNextArrow = (props) => (
    <div className="blog-arrow-right" onClick={props.onClick}>
      <ArrowImage src={BlueRight} alt={BlueRight} />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Container>
      <SectionWrapper>
        <HeadingContainer>
          <HeadingTitle>
            <Heading>
              Featured Blog <Line />
            </Heading>
            <SubHeading>Learn about our latest news from blog.</SubHeading>
          </HeadingTitle>
          <HeadingDescription>
            <Description>
              Follow our latest news and thoughts which focuses exclusively on
              design, art, vintage, and also work updates.
            </Description>
          </HeadingDescription>
        </HeadingContainer>
        <Slider {...settings}>
          {blogData.map((blog, i) => {
            const dateParts = blog?.date.split(" ");
            const day = dateParts[0];
            const monthYear = dateParts[1];

            return (
              <CardContainer key={i}>
                <Cards>
                  <BlogImage src={blog.image} alt={blog.image} />
                  <DateConatiner>
                    <DateCard>
                      <Date>{day}</Date>
                      <MonthYear>{monthYear}</MonthYear>
                    </DateCard>
                  </DateConatiner>
                  <AboutBlogContainer>
                    <IconContainer>
                      <IconTest>
                        <Admin />
                        <Text> {blog.about} </Text>
                      </IconTest>
                      <IconTest>
                        <File />
                        <Text> {blog.aboutBlog}</Text>
                      </IconTest>
                    </IconContainer>
                    <CardHeading>{blog.heading}</CardHeading>
                    <LineConatiner>
                      <Linetwo />
                    </LineConatiner>
                    <BlogDiscription>{blog.discription}</BlogDiscription>
                    <ReadMoreButton>
                      <ButtonText>Read more </ButtonText>
                      <Plues>+</Plues>
                    </ReadMoreButton>
                  </AboutBlogContainer>
                </Cards>
              </CardContainer>
            );
          })}
        </Slider>
      </SectionWrapper>
    </Container>
  );
};
