import { styled } from "styled-components";

export const SectionWrapper = styled.section`
  width: 80%;
  margin: auto;

  @media screen and (min-width: 1921px) {
    width: 70%;
    margin: auto;
  }

  @media screen and (max-width: 800px) {
    width: 90%;
    margin: auto;
  }
`;

export const SectionSpacer = styled.section`
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative;
`;
