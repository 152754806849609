import { styled } from "styled-components";
import { SectionWrapper } from "../../components/Common";
import { BlueLeft, BlueRight, Stars, colors } from "../../assets";
import Slider from "react-slick";
import { ClientTestimonialsData } from "../../assets/constants/content";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Container = styled.div`
  margin: 3rem 0;
  @media screen and (max-width: 600px) {
    margin: 3rem 0rem 4rem 0rem;
  }
`;

const HeadingContainer = styled.div`
  margin: 10px;
`;

const Heading = styled.p`
  color: ${colors.headColorBlue};
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  line-height: 29.25px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Line = styled.p`
  background: ${colors.primaryBlueColor};
  width: 30px;
  height: 2px;
  flex-shrink: 0;
  margin-left: 1rem;
`;

const SubHeading = styled.h6`
  color: ${colors.darkBlueColor};
  font-size: 36px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 46.8px;
  text-align: start;
  width: 45%;

  @media screen and (max-width: 800px) {
    width: 80%;
    font-size: 28px;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Cards = styled.div`
  border-radius: 4px;
  border: 1px solid ${colors.primaryBlueColor};
  background: #fff;
  padding: 31px 33px 45px 33px;
  margin: 10px;
`;

const CardDiscription = styled.p`
  color: ${colors.headColorBlue};
  font-size: 15px;
  font-family: Rubik;
  line-height: 26px;
  text-align: start;
`;
const CardContainer = styled.div`
  width: 100%;
`;
const ClintImage = styled.img`
  height: 75px;
  width: 75px;
`;

const ClientContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const ClientName = styled.p`
  color: ${colors.primaryBlueColor};
  font-size: 17px;
  font-family: Poppins;
  font-weight: 700;
  line-height: 20.4px;
`;
const ClientRole = styled.p`
  color: ${colors.headColorBlue};
  font-size: 14px;
  font-family: Rubik;
  font-weight: 500;
  line-height: 22.75px;
`;

const ArrowImage = styled.img`
  width: 18px;
  height: 24px;
  flex-shrink: 0;
`;

const StartConatiner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-top: 6px;
`;

const StartImage = styled.img`
  width: 14px;
  height: 14px;
`;

export const ClientsTestimonials = () => {
  const CustomPrevArrow = (props) => (
    <div className="slick-arrow-left" onClick={props.onClick}>
      <ArrowImage src={BlueLeft} alt={BlueLeft} />
    </div>
  );

  const CustomNextArrow = (props) => (
    <div className="slick-arrow-right" onClick={props.onClick}>
      <ArrowImage src={BlueRight} alt={BlueRight} />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    prevArrow: <CustomPrevArrow />, // Custom previous arrow component
    nextArrow: <CustomNextArrow />, // Custom next arrow component
    speed: 500,
    slidesToShow: 2, // Number of cards to show at once
    slidesToScroll: 1, // Number of cards to scroll at a time
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      // Add more breakpoints and settings as needed
    ],
  };

  return (
    <Container>
      <SectionWrapper>
        <HeadingContainer>
          <Heading>
            Client’s Testimonials <Line />
          </Heading>
          <SubHeading>We are very happy for client’s reviews.</SubHeading>
        </HeadingContainer>
        <Slider {...settings}>
          {ClientTestimonialsData.map((clients, i) => {
            return (
              <CardContainer key={i}>
                <Cards>
                  <CardDiscription>{clients.discription}</CardDiscription>
                  <ClientContainer>
                    <ClintImage src={clients.image} alt={clients.image} />
                    <DetailsContainer>
                      <ClientName>{clients.name}</ClientName>
                      <ClientRole>{clients.role}</ClientRole>
                      <StartConatiner>
                        {[...Array(clients.star)].map((_, index) => (
                          <StartImage key={index} src={Stars} alt="Star" />
                        ))}
                      </StartConatiner>
                    </DetailsContainer>
                  </ClientContainer>
                </Cards>
              </CardContainer>
            );
          })}
        </Slider>
      </SectionWrapper>
    </Container>
  );
};
