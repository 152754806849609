import { styled } from "styled-components";
import { SectionWrapper } from "../../components/Common";
import { FlipCardComponent } from "../../components";
import { flipCardData } from "../../assets/constants/content";

const RecruitmentCountriesSection = styled.section``;

const SectionTitle = styled.section`
  font-family: Poppins;
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
  width: 65%;
  margin: auto;
  padding: 3rem 0;

  @media screen and (max-width: 800px) {
    font-size: 32px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
  @media screen and (max-width: 800px) {
    padding: 1rem 0;
  }
`;

const FlipCardsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;

  @media screen and (max-width: 1500px) {
    gap: 1.5rem;
  }
  @media screen and (max-width: 1100px) {
    gap: 2rem;
  }
  @media screen and (max-width: 600px) {
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 0rem;
  }
`;
export const RecruitmentCountries = () => {
  return (
    <RecruitmentCountriesSection>
      <SectionWrapper>
        <SectionTitle> دول الاستقدام </SectionTitle>
        <FlipCardsWrapper>
          {flipCardData.map((card, index) => {
            return <FlipCardComponent data={card} />;
          })}
        </FlipCardsWrapper>
      </SectionWrapper>
    </RecruitmentCountriesSection>
  );
};
