import { MainNav, SubNav } from "../../components";
import { useWindowWidth } from "@react-hook/window-size";

export const Navbar = () => {
  const windowWidth = useWindowWidth();
  return (
    <div className="testing">
      {windowWidth > 1024 && <SubNav />}
      <MainNav />
    </div>
  );
};
