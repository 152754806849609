import { styled } from "styled-components";
import { SectionWrapper } from "../Common";
import { CleaningLogoIcon, MenuIcon, colors, Logo } from "../../assets";
import { MenuData, styles } from "../../assets/constants/content";
import { Link } from "react-router-dom";
import { useWindowWidth } from "@react-hook/window-size";
import { slide as Menu } from "react-burger-menu";

const MainNavContainer = styled.div`
  width: 100%;
  padding: 0.8rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Rubik", sans-serif;
  color: ${colors.darkBlueColor};
`;

const ExpandableContainer = styled.div`
  color: ${colors.darkBlueColor};
  font-weight: 600;
  font-size: 1rem;
  margin: auto;

  span {
    white-space: nowrap;
  }

  &:hover {
    cursor: pointer;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  gap: 3rem;
  font-family: "Poppins", sans-serif;
`;

const LinkComponent = styled(Link)`
  text-decoration: none;
  color: ${colors.darkBlueColor};
  font-weight: 600;
  font-size: 1rem;
  margin: auto;
`;

const AppointmentButton = styled.button`
  background-color: ${colors.primaryBlueColor};
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 1rem 1.6rem;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  color: ${colors.whiteColor};
  transition: 0.3s ease;

  &:hover {
    cursor: pointer;
    background-color: ${colors.darkBlueColor};
  }
`;

const LogoImage = styled.img`
  width: 200px;
`;

const MenuTitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const MainNav = () => {
  const onlyWidth = useWindowWidth();
  return (
    <>
      <SectionWrapper>
        <MainNavContainer>
          {onlyWidth > 1024 ? (
            <>
              <Link to="/">
                {/* <CleaningLogoIcon /> */}
                <LogoImage src={Logo} alt="" />
              </Link>
              <MenuWrapper>
                {MenuData?.map((menuItem, index) => {
                  if (menuItem.url.length >= 1) {
                    return (
                      <LinkComponent
                        key={menuItem?.title + index}
                        to={menuItem?.url}
                      >
                        {" "}
                        {menuItem?.title}{" "}
                      </LinkComponent>
                    );
                  } else {
                    return (
                      <ExpandableContainer key={menuItem?.title + index}>
                        <span>
                          {" "}
                          {menuItem?.title} {menuItem?.isExpandable && <>+</>}
                        </span>
                      </ExpandableContainer>
                    );
                  }
                })}
              </MenuWrapper>
              <Link to="/recruitment">
                <AppointmentButton>للاستقدام</AppointmentButton>
              </Link>
            </>
          ) : (
            <MenuTitleContainer>
              <Link to="/">
                <LogoImage src={Logo} alt="" />
              </Link>{" "}
              <Menu
                customBurgerIcon={<MenuIcon />}
                right
                pageWrapId={"page-wrap"}
                styles={styles}
              >
                <a className="menu-item" href="/">
                  الرئيسية
                </a>
                <a className="menu-item" href="/about">
                  من نحن
                </a>
                {/* <a className="menu-item" href="/menu">
                  Menu
                </a> */}
                <a className="menu-item" href="/contact">
                  التواصل
                </a>
                <Link to="/recruitment">
                  <AppointmentButton>للاستقدام</AppointmentButton>
                </Link>
              </Menu>
            </MenuTitleContainer>
          )}
        </MainNavContainer>
      </SectionWrapper>
    </>
  );
};
