import { styled } from "styled-components";
import { MinusAccordion, PlusAccordion, colors } from "../../assets";
import { SectionSpacer, SectionWrapper } from "../../components/Common";
import { faqData } from "../../assets/constants/content";
import { useState } from "react";

const FaqSection = styled.section`
  width: 100%;
  background: ${colors.grayBgColor};
`;

const SectionTitle = styled.h2`
  font-family: Poppins;
  font-size: 36px;
  font-weight: 700;
  line-height: 47px;
  letter-spacing: 0em;
  text-align: center;
  width: 65%;
  margin: auto;

  @media screen and (max-width: 800px) {
    font-size: 32px;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const FaqCardsWrapper = styled.div`
  /* display: grid;
  grid-template-columns: auto auto; */
  display: flex;
  gap: 2rem;
  max-width: 100%;
  margin: 4rem 0;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const FaqRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
`;

const FaqCard = styled.div`
  padding: 2rem;
  border-radius: 1rem;
  background-color: ${colors.whiteColor};
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media screen and (max-width: 768px) {
    padding: 1.5rem;
  }
`;

const FaqHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
  svg {
    width: 30px;
    height: 30px;
  }

  @media screen and (max-width: 768px) {
    gap: 4rem;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const FaqTitle = styled.p`
  font-weight: 600;
  font-size: 1.1rem;
  text-align: left;
  font-family: "Rubik", sans-serif;

  @media screen and (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const FaqBody = styled.div`
  display: none;
  transition: 1s ease;
  transition-delay: 2s;
  transition-duration: 2s;

  &.open {
    display: block;
  }

  &.close {
    display: none;
  }
`;

const FaqAnswer = styled.p`
  text-align: left;
  width: 80%;
  line-height: 150%;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-style: italic;
  color: ${colors.darkBlueColor};

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const FaqCardActionIcon = styled.div`
  transition: all 2s ease;
  display: flex;
`;

export const FAQ = () => {
  const [selectedAccordion, setSelectedAccordion] = useState([]);

  return (
    <>
      <FaqSection>
        <SectionWrapper>
          <SectionSpacer>
            <SectionTitle>اسئلة متكررة</SectionTitle>
            <FaqCardsWrapper>
              {faqData?.map((faq, faqIndex) => {
                const faqNObjectName = Object.keys(faq)[0];
                const faqObjectArray = faq[faqNObjectName];
                return (
                  <FaqRow key={faqIndex}>
                    {faqObjectArray.map((faqCard, faqCardIndex) => {
                      return (
                        <FaqCard key={faqCardIndex}>
                          <FaqHeader>
                            <FaqTitle> {faqCard?.title} </FaqTitle>
                            <FaqCardActionIcon
                              onClick={() => {
                                if (
                                  selectedAccordion.some(
                                    (item) =>
                                      item.faqCardIndex === faqCardIndex &&
                                      item.faqRow === faqIndex
                                  )
                                ) {
                                  setSelectedAccordion(
                                    selectedAccordion.filter(
                                      (item) =>
                                        item.faqCardIndex !== faqCardIndex ||
                                        item.faqRow !== faqIndex
                                    )
                                  );
                                } else {
                                  setSelectedAccordion([
                                    ...selectedAccordion,
                                    {
                                      faqCardIndex: faqCardIndex,
                                      faqRow: faqIndex,
                                    },
                                  ]);
                                }
                              }}
                            >
                              {selectedAccordion.some(
                                (item) =>
                                  item.faqCardIndex === faqCardIndex &&
                                  item.faqRow === faqIndex
                              ) ? (
                                <MinusAccordion />
                              ) : (
                                <PlusAccordion />
                              )}
                            </FaqCardActionIcon>
                          </FaqHeader>

                          <FaqBody
                            className={
                              selectedAccordion.some(
                                (item) =>
                                  item.faqCardIndex === faqCardIndex &&
                                  item.faqRow === faqIndex
                              )
                                ? "open"
                                : "close"
                            }
                          >
                            <FaqAnswer>{faqCard?.answer}</FaqAnswer>
                          </FaqBody>

                          {/* {selectedAccordion.some(
                            (item) =>
                              item.faqCardIndex === faqCardIndex &&
                              item.faqRow === faqIndex
                          ) && (
                            <FaqBody>
                              <FaqAnswer>{faqCard?.answer}</FaqAnswer>
                            </FaqBody>
                          )} */}
                        </FaqCard>
                      );
                    })}
                  </FaqRow>
                );
              })}
            </FaqCardsWrapper>
          </SectionSpacer>
        </SectionWrapper>
      </FaqSection>
    </>
  );
};
