import React, { Fragment } from "react";
import { RecruitmentBanner } from "../../components";
import { ContactBG, colors } from "../../assets";
import { styled } from "styled-components";
import { ContactForm, Footer } from "../../containers";

const ContactContainer = styled.div`
  position: relative;
`;
const ContactBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: ${colors.contactbannerLightBLueColor};
`;
export const Contact = () => {
  return (
    <Fragment>
      <ContactContainer>
        <RecruitmentBanner img={ContactBG} positions="center" />
        <ContactBackground></ContactBackground>
      </ContactContainer>
      <ContactForm />
      <Footer />
    </Fragment>
  );
};
