import { RecruitmentBg } from "../../assets";
import { RecruitmentBanner } from "../../components";
import {
  Footer,
  RecruitmentCountries,
  RecruitmentProcess,
} from "../../containers";

export const Recruitment = () => {
  return (
    <>
      <RecruitmentBanner img={RecruitmentBg} positions="bottom" />
      <RecruitmentCountries />
      <RecruitmentProcess />
      <Footer />
    </>
  );
};
