import React from "react";
import { styled } from "styled-components";
import { colors } from "../../assets";
import { RecruitmentTimelineData } from "../../assets/constants/content";

const TimelineStepsContainer = styled.div`
  display: flex;
  font-family: "Rubik", sans-serif;
  font-weight: 700;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;

const BoxRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  @media screen and (max-width: 800px) {
    margin-top: 0;
  }
`;
const BoxButton = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;
const BoxDataContainer = styled.div`
  border: 2px solid ${colors.darkBlueColor};
  border-radius: 10px;
  padding: 2rem;
  margin: 0 1rem;
  gap: 5px;
  display: flex;
  height: 70px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 2px 3px 7px 1px #0000a9;

  @media screen and (max-width: 1500px) {
    padding: 1rem;
    margin: 0 6px;
  }
`;
const Boxlabel = styled.span`
  color: ${colors.darkBlueColor};
  text-align: left;
  font-size: 1.2rem;

  @media screen and (max-width: 1500px) {
    font-size: 13px;
  }
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

const Boxdescription = styled.p`
  font-size: 1rem;
  color: ${colors.grayTextColor};
  text-align: left;

  @media screen and (max-width: 1500px) {
    font-size: 11px;
  }
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1rem;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

const Boxicon = styled.div`
  width: 30px;
  height: 30px;
  padding: 8px;
  margin-bottom: 8px;
  border-radius: 50%;
  border: 4px solid #000e39de;
  @media screen and (max-width: 800px) {
    margin-bottom: 0;
  }
`;

const Boxtag = styled.div`
  flex-grow: 1;
  min-width: 20px;
  height: 4px;
  background-color: #000e39de;

  @media screen and (max-width: 800px) {
    min-width: 3px;
    height: 70px;
  }
`;

const LeftTag = styled.div`
  background-color: white;
`;

const RightTag = styled.div`
  background-color: white;
`;
export const TimelineSteps = () => {
  return (
    <TimelineStepsContainer>
      {RecruitmentTimelineData.map((timeline, index) => {
        return (
          <>
            <BoxRow>
              <Box>
                <BoxButton>
                  <Boxtag>
                    <LeftTag></LeftTag>
                  </Boxtag>
                  <Boxicon>{timeline.icon} </Boxicon>
                  <Boxtag>
                    <RightTag></RightTag>
                  </Boxtag>
                </BoxButton>
                <BoxDataContainer>
                  <Boxlabel>{timeline.Title}</Boxlabel>
                  <Boxdescription>{timeline.description}</Boxdescription>
                </BoxDataContainer>
              </Box>
            </BoxRow>
          </>
        );
      })}
    </TimelineStepsContainer>
  );
};
