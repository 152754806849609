import React from "react";
import { styled } from "styled-components";
import { RecruitmentBg, colors } from "../../assets";

const BannerContainer = styled.div`
  height: 80vh;
  /* background: url(${RecruitmentBg}); */
  background-position: ${(props) => props.positions};
  background-size: cover;
  background-repeat: no-repeat;
  color: ${colors.whiteColor};
  background-image: url(${(props) => props.img});
`;
export const RecruitmentBanner = ({ img, positions }) => {
  return <BannerContainer img={img} positions={positions}></BannerContainer>;
};
