import React from "react";
import { styled } from "styled-components";
import { SectionSpacer, SectionWrapper } from "../../components/Common";
import { colors } from "../../assets";

const ContactFormContainer = styled.div`
  width: 100%;
  margin: auto;
  font-family: "Rubik", sans-serif;
  display: flex;
  align-items: center;
  gap: 4rem;
  justify-content: center;

  @media screen and (max-width: 1100px) {
    gap: 3rem;
  }

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;
const ContactFormWrapper = styled.div``;
const ContactMap = styled.div`
  width: 50%;
  margin: auto;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

const Contact = styled.iframe`
  width: 100%;
  height: 575px;
  border: 1px solid #ccc;

  @media screen and (max-width: 1500px) {
    height: 560px;
  }
  @media screen and (max-width: 1100px) {
    height: 485px;
  }
`;
const FormContainer = styled.div`
  width: 50%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border: 1px solid;
  padding: 4rem;
  border-radius: 5px;

  @media screen and (max-width: 1100px) {
    padding: 2rem;
  }
  @media screen and (max-width: 800px) {
    width: 80%;
    padding: 4rem;
  }

  @media screen and (max-width: 600px) {
    padding: 2rem;
  }
`;
const FormInput = styled.input`
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  background-color: initial;
  /* border-color: rgb(255 255 255 / var(--tw-border-opacity)); */
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  /* color: rgb(255 255 255 / var(--tw-text-opacity)); */
  font-family: Montserrat;
  font-size: 16px;
  line-height: 19px;
  padding: 20px 32px;
  color: black;
  width: 100%;
  font-family: "Rubik", sans-serif;

  &:focus-visible {
    outline-color: ${colors.primaryBlueColor} !important;
  }
  &:focus {
    border-color: ${colors.primaryBlueColor} !important;
  }
  @media screen and (max-width: 1500px) {
    padding: 18px 24px;
  }
  @media screen and (max-width: 1100px) {
    padding: 15px 30px;
  }
  @media screen and (max-width: 800px) {
    padding: 20px 32px;
  }
  @media screen and (max-width: 600px) {
    padding: 15px 30px;
  }
`;

const FormTextField = styled.textarea`
  --tw-border-opacity: 1;
  --tw-text-opacity: 1;
  background-color: initial;
  /* border-color: rgb(255 255 255 / var(--tw-border-opacity)); */
  border-radius: 0.25rem;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  /* color: rgb(255 255 255 / var(--tw-text-opacity)); */
  font-family: Montserrat;
  font-size: 16px;
  line-height: 19px;
  padding: 20px 32px;
  color: black;
  width: 100%;
  font-family: "Rubik", sans-serif;

  &:focus-visible {
    outline-color: ${colors.primaryBlueColor} !important;
  }
  &:focus {
    border-color: ${colors.primaryBlueColor} !important;
  }

  @media screen and (max-width: 1500px) {
    padding: 18px 24px;
  }
  @media screen and (max-width: 600px) {
    padding: 15px 30px;
  }
`;

const FormInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;

  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 84%;
    margin: auto;
  }
  @media screen and (max-width: 350px) {
    width: 78%;
    margin: auto;
  }
`;

const FormHeading = styled.h1`
  font-size: 30px;
  font-weight: 500;
  padding-bottom: 1rem;
  color: ${colors.primaryBlueColor};

  @media screen and (max-width: 600px) {
    font-size: 28px;
  }
  @media screen and (max-width: 350px) {
    font-size: 25px;
  }
`;
const AppointmentButton = styled.button`
  background-color: ${colors.primaryBlueColor};
  outline: none;
  border: none;
  border-radius: 3px;
  padding: 1rem 3.6rem;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  color: ${colors.whiteColor};
  transition: 0.3s ease;
  text-align: left;

  &:hover {
    cursor: pointer;
    background-color: ${colors.darkBlueColor};
  }
`;
const ButtonContainer = styled.div``;

export const ContactForm = () => {
  return (
    <SectionWrapper>
      <SectionSpacer>
        <ContactFormContainer>
          <ContactMap>
            <Contact
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3627.9190662612696!2d46.596126600000005!3d24.591989199999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1122d8e27c6d%3A0xc862614e6904c3a9!2z2LTYsdmD2Kkg2YXZh9mG2K8g2YTZhNin2LPYqtmC2K_Yp9mFIE11aGFubmFkIFJlY3J1aXRtZW50IENvbXBhbnk!5e0!3m2!1sen!2sin!4v1711488087800!5m2!1sen!2sin"
              title="Example iframe"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></Contact>
          </ContactMap>
          <FormContainer>
            <FormHeading> تواصل معنا</FormHeading>
            <FormInputWrapper>
              <FormInput placeholder="الاسم الاول" type="text"></FormInput>
              <FormInput placeholder="اسم العائلة" type="text"></FormInput>
            </FormInputWrapper>
            <FormInputWrapper>
              <FormInput placeholder="رقم الجوال" type="number"></FormInput>
              <FormInput placeholder="إيميل"></FormInput>
            </FormInputWrapper>
            <FormInputWrapper>
              <FormInput placeholder="الموضوع" type="text"></FormInput>
            </FormInputWrapper>
            <FormInputWrapper>
              <FormTextField placeholder="الرسالة" type="text"></FormTextField>
            </FormInputWrapper>
            <ButtonContainer>
              <AppointmentButton>إرسال</AppointmentButton>
            </ButtonContainer>
          </FormContainer>
        </ContactFormContainer>
      </SectionSpacer>
    </SectionWrapper>
  );
};
